import { FC, SVGAttributes } from "react";

const ChevronTextSmall: FC<SVGAttributes<SVGSVGElement>> = (rest) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    stroke="black"
    strokeWidth="1.5"
    strokeLinecap="round"
    strokeLinejoin="round"
    {...rest}
  >
    <path d="M7,14L11,10L7,6" />
  </svg>
);

export { ChevronTextSmall };
