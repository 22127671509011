import { FC } from "react";
import { Heading } from "@otrium/atoms";
import { Box } from "@otrium/core";
import { Trans } from "@lingui/macro";
import Link from "next/link";
import { HyperLink } from "src/atoms/HyperLink";
import {
  StyledCarouselHeader,
  StyledTitleWrapper,
} from "./CarouselHeader.styled";
import { StyledSubtitle } from "src/molecules/ProductCarousel/ProductCarouselInfoText/ProductCarouselInfoText.styled";
import { sanitize } from "isomorphic-dompurify";
import NoSSR from "src/atoms/NoSSR";
import { ChevronTextSmall } from "src/icons/ChevronTextSmall";

const CarouselHeaderTitle: FC<{
  title: string | JSX.Element;
  withChevron?: boolean;
  onClick?: () => void;
}> = ({ title, withChevron = false, onClick }) => {
  return (
    <Heading
      as="h3"
      fontFamily="PlusJakartaSans"
      fontSize={[2, 2, 3, 3]}
      color="tone.black"
      sx={{
        lineHeight: 1.25,
        textTransform: "none",
      }}
      onClick={onClick}
    >
      {typeof title === "string" ? (
        <span
          dangerouslySetInnerHTML={{
            __html: sanitize(title),
          }}
        />
      ) : (
        title
      )}
      <NoSSR>{withChevron && <ChevronTextSmall role="presentation" />}</NoSSR>
    </Heading>
  );
};

const CarouselHeaderSubtitle: FC<{ subTitle: string }> = ({ subTitle }) => {
  return (
    <StyledSubtitle
      as="h3"
      color="tone.black"
      fontWeight="normal"
      fontSize={["fontSize16", "fontSize16", "fontSize16", "fontSize18"]}
    >
      <span
        dangerouslySetInnerHTML={{
          __html: sanitize(subTitle),
        }}
      />
    </StyledSubtitle>
  );
};

interface CarouselHeaderProps {
  title?: string | JSX.Element;
  subTitle?: string;
  href?: string;
  as?: string;
  prefetch?: boolean;
  viewAllOnClick?: () => void;
  isGated?: boolean;
}

const CarouselHeader: FC<CarouselHeaderProps> = ({
  title,
  subTitle,
  href,
  as,
  prefetch = true,
  viewAllOnClick,
  isGated,
}) => {
  return (
    <Box pb="space16">
      <StyledCarouselHeader
        justifyContent="space-between"
        alignItems="flex-start"
        flexWrap="wrap"
      >
        {title && (
          <StyledTitleWrapper>
            {href ? (
              <Link
                href={href}
                {...(as ? { as } : {})}
                {...(!prefetch ? { prefetch } : {})}
                passHref
              >
                <CarouselHeaderTitle title={title} withChevron />
              </Link>
            ) : isGated ? (
              <CarouselHeaderTitle
                title={title}
                onClick={viewAllOnClick}
                withChevron={!!viewAllOnClick}
              />
            ) : (
              <CarouselHeaderTitle title={title} />
            )}
          </StyledTitleWrapper>
        )}
        <Box
          pb={[3, 3, 3, 3, 0]}
          display={["none", "none", "block", "block"]}
          sx={{ zIndex: 2 }}
        >
          {href ? (
            <Link
              href={href}
              {...(as ? { as } : {})}
              {...(!prefetch ? { prefetch } : {})}
              passHref
              data-testid="carousel-header-link"
              legacyBehavior
            >
              <HyperLink hasUnderline onClick={viewAllOnClick}>
                <Trans>View all</Trans>
              </HyperLink>
            </Link>
          ) : (
            viewAllOnClick && (
              <HyperLink hasUnderline onClick={viewAllOnClick}>
                <Trans>View all</Trans>
              </HyperLink>
            )
          )}
        </Box>
      </StyledCarouselHeader>
      {subTitle && (
        <Box mx={["space16", "space16", "space16", "space0"]}>
          <CarouselHeaderSubtitle subTitle={subTitle} />
        </Box>
      )}
    </Box>
  );
};

export default CarouselHeader;
